import _ from 'underscore';
import dayjs from '../../../../shared/services/dayjs.js';

import basedataService from '../services/basedataService.js';
import stammdatenService from '../services/stammdatenService.js';
import { collectValues, displayValues, fuelleSelectOptionen } from '../util.js';
import { debounceSpeichereMA, validateAbgaben } from './ma_daten.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereAbgaben = speichereAbgaben;
window.myHandlers.speichereKrankenkasseMA = speichereKrankenkasseMA;
window.myHandlers.inputRVNr = inputRVNr;
window.myHandlers.editKinderGeburtstage = editKinderGeburtstage;
window.myHandlers.addKindGeburtstag = addKindGeburtstag;
window.myHandlers.confirmKinderGeburtstage = confirmKinderGeburtstage;

/**
 * Bestückt die Abgaben Oberfläche mit relevanten Werten des Mitarbeiters
 * @param {object} aktuellerMA
 */
async function ladeAbgaben(aktuellerMA) {
    if (!_.isEmpty(aktuellerMA)) {
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        // Konfessionen laden...
        const konfessionen = await basedataService.holeKonfessionBasedataProvider();
        let konfessionSelect = document.querySelector('[aria-label="ma-KonfessionArbeitnehmer"]');
        fuelleSelectOptionen(konfessionSelect, konfessionen, 'Konfessionskuerzel', 'Konfessionsbezeichnung');
        konfessionSelect = document.querySelector('[aria-label="ma-KonfessionEhegatte"]');
        fuelleSelectOptionen(konfessionSelect, konfessionen, 'Konfessionskuerzel', 'Konfessionsbezeichnung');
        // Krankenkassen laden...
        const krankenkassen = await basedataService.holeKrankenkassenBasedataProvider();
        const krankenkasseSelect = document.querySelector('[aria-label="ma-Krankenkasse-KrankenkasseID"]');
        fuelleSelectOptionen(krankenkasseSelect, krankenkassen, 'Betriebsnummer', ['Kurzbezeichnung', 'Betriebsnummer']);
        // privat versichert als option
        const privatOption = document.createElement('option');
        privatOption.value = '00000000';
        privatOption.innerText = 'privatversichert';
        krankenkasseSelect.insertBefore(privatOption, krankenkasseSelect.firstChild);
        krankenkasseSelect.value = aktuelleBeschaeftigung.Krankenkasse.Betriebsnummer;
        // Steuerdaten laden...
        const htmlParentSteuer = document.getElementById('ma-Steuer');
        displayValues(aktuelleBeschaeftigung.Steuer, '', htmlParentSteuer, 'ma-');
        // lade Unfallversicherung
        displayValues(aktuelleBeschaeftigung.Berufsgenossenschaft, '', htmlParentSteuer, 'ma-Berufsgenossenschaft-');
        // lade Sozialversicherungsdaten
        const htmlParentSozial = document.getElementById('ma-Sozialabgaben');
        displayValues(aktuelleBeschaeftigung.Sozialversicherung, '', htmlParentSozial, 'ma-');
        // lade Kinder Geburtstage
        const htmlKinderGeburtstageAnzahl = htmlParentSozial.querySelector('[aria-label="ma-AnzahlKinderGeburtstage"]');
        if (aktuelleBeschaeftigung.Sozialversicherung?.GeburtstageKinder) {
            const kinderU25 = berechneKinderUnter25(aktuelleBeschaeftigung.Sozialversicherung?.GeburtstageKinder);
            htmlKinderGeburtstageAnzahl.innerText = `${aktuelleBeschaeftigung.Sozialversicherung?.GeburtstageKinder?.length} (${kinderU25})`;
        }
        // lade Versicherungen/Krankenkasse
        const htmlParentVersicherung = document.getElementById('ma-Versicherungen');
        // Krankenversicherungsnummer laden
        displayValues(aktuelleBeschaeftigung.Sozialversicherung, '', htmlParentVersicherung, 'ma-');
        // lade Krankenkasse Bezeichnung + Betriebsnr
        displayValues(aktuelleBeschaeftigung.Krankenkasse, '', htmlParentVersicherung, 'ma-Krankenkasse-');// lade freiwillige Krankenkasse
        displayValues(aktuelleBeschaeftigung.FreiwilligeGesetzlicheKrankenversicherung, '', htmlParentVersicherung, 'ma-kkfreiwillig-');
        // lade freiwillige Pflegeversicherung
        displayValues(aktuelleBeschaeftigung.FreiwilligeGesetzlichePflegeversicherung, '', htmlParentVersicherung, 'ma-pvfreiwillig-');
        // lade private Krankenversicherung
        displayValues(aktuelleBeschaeftigung.PrivateKrankenkasse, '', htmlParentVersicherung, 'ma-kkprivat-');
        validateAbgaben(aktuellerMA);
        // Minijob Knappschaft Info
        const kkGfgInfo = document.getElementById('kk-info-gfg');
        kkGfgInfo.style.display = aktuelleBeschaeftigung.Personengruppenschluessel == '109' || aktuelleBeschaeftigung.Personengruppenschluessel == '110' ? 'grid' : 'none';
            
    } else {
        await SystemDialogService.instance.displayAsync('kein-ma-alert-dialog');
    }
}

/**
 * Krankenkasse Select Handler
 */
async function speichereKrankenkasseMA() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (!_.isEmpty(aktuellerMA)) {
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        const krankenkassen = await basedataService.holeKrankenkassenBasedataProvider();
        const htmlParentVersicherung = document.getElementById('ma-Versicherungen');
        const auswahlKK = htmlParentVersicherung.querySelector('[aria-label="ma-Krankenkasse-KrankenkasseID"]').value;
        const KKBez = htmlParentVersicherung.querySelector('[aria-label="ma-Krankenkasse-Krankenkasse"]');
        const KKBNr = htmlParentVersicherung.querySelector('[aria-label="ma-Krankenkasse-Betriebsnummer"]');
        if (auswahlKK === '00000000') {
            KKBez.value = 'privatversichert'; // value update
            KKBez.title = 'privatversichert'; // title/tooltip update
            KKBNr.value = '00000000';
            KKBNr.title = '00000000';
            aktuelleBeschaeftigung.Krankenkasse.Satz = 0;
            aktuelleBeschaeftigung.Krankenkasse.ZusatzSatz = 0;
            htmlParentVersicherung.querySelector('[aria-label="ma-kkprivat-PrivatVersichert"]').checked = true;
            aktuelleBeschaeftigung.PrivateKrankenkasse.PrivatVersichert = true;
            // BGSKV und BGSPV sind bei privatversichterten 0
            document.body.querySelector('[aria-label="ma-BeitragsgruppenschluesselKrankenversicherung"]').value = 0;
            aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselKrankenversicherung = 0;
            document.body.querySelector('[aria-label="ma-BeitragsgruppenschluesselPflegeversicherung"]').value = 0;
            aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselPflegeversicherung = 0;
        } else {
            const auswahlKKObject = krankenkassen.find((kk) => kk.Betriebsnummer === auswahlKK);
            const auswahlKKBez = _.isEmpty(auswahlKKObject) || '' ? '' : auswahlKKObject.Kurzbezeichnung;
            KKBez.value = auswahlKKBez; // value update
            KKBez.title = auswahlKKBez; // title/tooltip update
            KKBNr.value = auswahlKK;
            KKBNr.title = auswahlKK;
            aktuelleBeschaeftigung.Krankenkasse.Satz = auswahlKKObject ? auswahlKKObject.Beitragssaetze[0].Allgemein : 0;
            aktuelleBeschaeftigung.Krankenkasse.ZusatzSatz = auswahlKKObject ? auswahlKKObject.Beitragssaetze[0].DZBAN : 0;
            htmlParentVersicherung.querySelector('[aria-label="ma-kkprivat-PrivatVersichert"]').checked = false;
            aktuelleBeschaeftigung.PrivateKrankenkasse.PrivatVersichert = false;
            const beschaeftigungen = await basedataService.holeBeschaeftigungsartenBasedataProvider();
            const beschaeftigung = beschaeftigungen.find((b) => b.BeschID === aktuelleBeschaeftigung.Beschaeftigungsart);
            // Wenn eine Beschäftigung vorliegt, übernehmen wir die BGSKV/BGSPV der Beschäftigungsart.
            if (beschaeftigung) {
                document.body.querySelector('[aria-label="ma-BeitragsgruppenschluesselKrankenversicherung"]').value = beschaeftigung.BGSKV;
                aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselKrankenversicherung = beschaeftigung.BGSKV;
                document.body.querySelector('[aria-label="ma-BeitragsgruppenschluesselPflegeversicherung"]').value = beschaeftigung.BGSPV;
                aktuelleBeschaeftigung.Sozialversicherung.BeitragsgruppenschluesselPflegeversicherung = beschaeftigung.BGSPV;
            }
        }
        await speichereAbgaben();
    }
}

/**
 * Liest die Abgaben des Mitarbeiters aus der Oberfläche und speichert diese per
 * debounceSpeichereMA()
 */
async function speichereAbgaben() {
    const aktuellerMA = stammdatenService.aktuellerMitarbeiter;
    if (!_.isEmpty(aktuellerMA)) {
        // TODO: Beschaeftigung auslesen...
        const aktuelleBeschaeftigung = aktuellerMA.Beschaeftigung[0];
        // Steuerdaten laden...
        const htmlParentSteuer = document.getElementById('ma-Steuer');
        collectValues(aktuelleBeschaeftigung.Steuer, 'ma-', htmlParentSteuer);
        // lade Unfallversicherung Gefahrklasse
        collectValues(aktuelleBeschaeftigung.Berufsgenossenschaft, 'ma-Berufsgenossenschaft-', htmlParentSteuer);
        // lade Sozialversicherungsdaten
        const htmlParentSozial = document.getElementById('ma-Sozialabgaben');
        collectValues(aktuelleBeschaeftigung.Sozialversicherung, 'ma-', htmlParentSozial);

        // lade Krankenkasse Bezeichnung + Betriebsnr. Betriebsnummer is abhängig von der Krankenkasse.
        const htmlParentVersicherung = document.getElementById('ma-Versicherungen');
        collectValues(aktuelleBeschaeftigung.Sozialversicherung, 'ma-', htmlParentVersicherung);
        collectValues(aktuelleBeschaeftigung.Krankenkasse, 'ma-Krankenkasse-', htmlParentVersicherung);
        // lade freiwillige Krankenkasse
        collectValues(aktuelleBeschaeftigung.FreiwilligeGesetzlicheKrankenversicherung, 'ma-kkfreiwillig-', htmlParentVersicherung);
        // lade freiwillige Pflegeversicherung
        collectValues(aktuelleBeschaeftigung.FreiwilligeGesetzlichePflegeversicherung, 'ma-pvfreiwillig-', htmlParentVersicherung);
        // lade private Krankenversicherung
        collectValues(aktuelleBeschaeftigung.PrivateKrankenkasse, 'ma-kkprivat-', htmlParentVersicherung);

        window.myVars.mitarbeiterEditiert = true;
        debounceSpeichereMA(aktuellerMA);
    }
}

/**
 * Forciert Großbuchstaben für die SV Nummer
 * @param {HTMLElement} thisElement SVNr Input Feld
 */
async function inputRVNr(thisElement) {
    thisElement.value = thisElement.value.toUpperCase();
    await speichereAbgaben();
}

/**
 * Fügt eine Zeile für ein neues Geburtsdatum eines Kindes ein
 * @param {*} thisElement 
 */
function addKindGeburtstag(thisElement) {
    const kindTemplate = document.body.querySelector('[ma-kinder-template]');
    const kinderListe = document.body.querySelectorAll('.ma-kinder-list');
    const newKind = kindTemplate.content.cloneNode(true).children[0];
    thisElement.insertAdjacentElement('beforebegin', newKind);
    newKind.children[0].focus();
    newKind.children[0].tabIndex = kinderListe.length * 2;
    newKind.children[1].tabIndex = kinderListe.length * 2 + 1;
}

/**
 * Öffnet die Kinder Geburtstage Dialogbox und zeigt bestehende an.
 */
async function editKinderGeburtstage() {
    const dialog = document.getElementById('ma-anzahl-kinder-dialog');
    const kinderListe = dialog.querySelectorAll('.ma-kinder-list');
    kinderListe.forEach((item) => item.remove());
    const mitarbeiter = stammdatenService.aktuellerMitarbeiter;
    const addKindButton = dialog.querySelector('#ma-add-kind-btn');
    const kinder = mitarbeiter.Beschaeftigung[0].Sozialversicherung.GeburtstageKinder;
    const kindTemplate = document.body.querySelector('[ma-kinder-template]');
    kinder.forEach((kind, index) => {
        const newKind = kindTemplate.content.cloneNode(true).children[0];
        addKindButton.insertAdjacentElement('beforebegin', newKind);
        newKind.children[0].value = kind.KindName;
        newKind.children[0].tabIndex = index * 2;
        newKind.children[1].value = kind.KindGeburtstag;
        newKind.children[1].tabIndex = index * 2 + 1;
    });
    const result = await SystemDialogService.instance.displayAsync(dialog.id);
    if (result.success) {
        const mitarbeiter = stammdatenService.aktuellerMitarbeiter;
        mitarbeiter.Beschaeftigung[0].Sozialversicherung.GeburtstageKinder = result.data.GeburtstageKinder;
        const kinderU25 = berechneKinderUnter25(result.data.GeburtstageKinder);
        document.body.querySelector('[aria-label="ma-AnzahlKinderGeburtstage"]').innerText = `${result.data.GeburtstageKinder.length} (${kinderU25})`;;
        debounceSpeichereMA(mitarbeiter);
    }
}

/**
 * Bestätigt die aktuelle eingabe an Kinder Geburtstagen und speichert diese im Mitarbeiterobjekt
 */
function confirmKinderGeburtstage() {
    const dialog = document.getElementById('ma-anzahl-kinder-dialog');
    const kinderListe = dialog.querySelectorAll('.ma-kinder-list');
    const kinderMA = [];
    kinderListe.forEach((kind, index) => {
        const vorname = kind.children[0].value;
        const geburtstag = kind.children[1].value;
        // Nur wenn ein Geburtsdatum vorhanden ist, speichern wir das Kind
        if (geburtstag) {
            const kindObject = {
                KindName: vorname ? vorname : `Kind ${index + 1}`,
                KindGeburtstag: geburtstag
            };
            kinderMA.push(kindObject);
        }
    });
    SystemDialogService.instance.confirm({
        GeburtstageKinder: kinderMA
    });
}

function berechneKinderUnter25(kinderMA) {
    const zeitraum = window.myVars.aktuellesMonatsDatum;
    let anzahlKinderUnter25 = 0;
    kinderMA.forEach((kind) => {
        if (zeitraum.diff(dayjs(kind.KindGeburtstag), 'year') < 25) {
            anzahlKinderUnter25++;
        }
    });
    return anzahlKinderUnter25;
}

export {
    ladeAbgaben
};
